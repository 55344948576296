import gsap from 'gsap';

class TabToggle {
	constructor(element) {
		if (!element) return;

		this.element = element;
		this.anchors = element.querySelectorAll('.c-tabs__anchor');
		this.select = element.querySelector('.c-tabs__select');
		this.tabs = element.querySelectorAll('.c-tabs__tab');

		this.anchors?.forEach((anchor) => {
			anchor.addEventListener('click', (e) => {
				e.preventDefault();

				let id = anchor.dataset.id;
				this.toggleTab(id, anchor);
			});
		});

		this.select?.addEventListener('change', (e) => {
			let id = e.target.value;
			this.toggleTab(id);
		});
	}

	toggleTab(id, anchor) {
		let target = this.element.querySelector(`.c-tabs__tab[data-id="${id}"]`);

		if (!target || target.classList.contains('c-tabs__tab--active')) return;

		const tl = gsap.timeline();
		const activeTab = Array.from(this.tabs).filter(
			(tab) => tab != target && tab.classList.contains('c-tabs__tab--active'),
		)[0];

		// Fade out current tab and fade in new tab
		tl.to(activeTab, {
			autoAlpha: 0,
			display: 'none',
			clearProps: 'all',
			onComplete: () => {
				activeTab.classList.remove('c-tabs__tab--active');
			},
		})
			.set(target, {
				autoAlpha: 0,
				onComplete: () => {
					target.classList.add('c-tabs__tab--active');
				},
			})
			.to(target, {
				autoAlpha: 1,
				clearProps: 'all',
			});

		// If there's an anchor, remove active class from all anchors and make this anchor active
		if (anchor) {
			this.anchors.forEach((anchor) => anchor.classList.remove('c-tabs__anchor--active'));
			anchor.classList.add('c-tabs__anchor--active');
		}
	}
}

export default TabToggle;
