import queryString from 'query-string';
import axios from 'axios';
import Accordion from './accordion';

class GridFilter {
	constructor(intake, paramName) {
		axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

		this.selectFilter = intake.querySelector('.grid-filter-select');
		this.gridList = intake.querySelector('.grid-filter-list');
		this.paramName = paramName;
	}

	init() {
		if (this.selectFilter) {
			this.selectFilter.addEventListener('change', (el) => {
				this.setUrl(el.target.value);
				this.getResults(el.target.value);
			});
		}
	}

	setUrl(value) {
		let params = queryString.parse(location.search);

		if (value !== 'triservice') {
			params[this.paramName] = value;
		} else {
			params[this.paramName] = undefined;
		}

		params = queryString.stringify(params);
		const url = `${location.origin}${location.pathname}?${params}`;
		history.pushState({}, 'filter', url);

		return url;
	}

	getResults(value) {
		const url = `${location.origin}${location.pathname}?${this.paramName}=${value}`;

		axios
			.get(url)
			.then((response) => {
				this.gridList.innerHTML = response.data;
				const accordion = new Accordion();
				accordion.init();
			})
			.catch((error) => {
				console.log(error);
			});
	}
}

const setGridFilters = () => {
	const currentIntake = document.querySelector('.intakes-current');
	const upcomingIntake = document.querySelector('.intakes-upcoming');
	const caseStudyPage = document.querySelector('.casestudies-filter');

	if (currentIntake) {
		const currentFilter = new GridFilter(currentIntake, 'current');
		currentFilter.init();
	}

	if (upcomingIntake) {
		const upcomingFilter = new GridFilter(upcomingIntake, 'upcoming');
		upcomingFilter.init();
	}

	if (caseStudyPage) {
		const caseStudyFilter = new GridFilter(caseStudyPage, 'service');
		caseStudyFilter.init();
	}
};

export default setGridFilters;
