class CodewordsCTA {
	constructor() {
		this.elements = document.querySelectorAll('.c-codewords-cta');

		if (!this.elements) return;

		this.elements.forEach((element) => this.init(element));
	}

	init(element) {
		const video = element.querySelector('video');
		const pause = element.querySelector('.pause-button');

		pause.style.setProperty('--pause-speed', `${video.duration * 1000}ms`);

		// Start/stop the video when it comes into/goes out of view
		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						video.play();
						pause.classList.add('pause-button--playing');
						pause.classList.remove('pause-button--paused');
					} else {
						video.pause();
						pause.classList.add('pause-button--paused');
						pause.classList.remove('pause-button--playing');
					}
				});
			},
			{
				rootMargin: '0% 0% 0% 0%',
			},
		);

		observer.observe(element);
	}
}

export default CodewordsCTA;
