import Velocity from 'velocity-animate';
import 'velocity-animate/velocity.ui';

class Accordion {
	constructor() {
		// TODO remove accordion-item variation once all instances are updated
		this.accordionItems = document.querySelectorAll('.accordion-item, .accordion-row');
	}

	init() {
		this.createEvents();
	}

	createEvents() {
		for (var i = 0; i < this.accordionItems.length; i++) {
			let item = this.accordionItems[i];
			let header = item.querySelector('.accordion-header, .accordion-row__heading');
			header.addEventListener('click', () => this.ToggleItem(item), false);
		}
	}

	ToggleItem(el) {
		if (!el.classList.contains('accordion-item--open')) {
			return this.openItem(el);
		} else {
			return this.closeItem(el);
		}
	}

	openItem(el) {
		let answer = el.querySelector('.accordion-answer, .accordion-row__content');
		if (answer) {
			el.classList.add('accordion-item--open');
			el.querySelector('.accordion-row__icon')?.classList.remove('rotate-45');
			el.querySelector('.accordion-open-button .svg-icon use')?.setAttribute(
				'xlink:href',
				'images/icons.svg#CTA-close',
			);

			Velocity(answer, 'slideDown', { duration: 200 });

			Velocity(answer, { opacity: 1 }, { duration: 200 });
		}
	}

	closeItem(el) {
		let answer = el.querySelector('.accordion-answer, .accordion-row__content');
		if (answer) {
			el.classList.remove('accordion-item--open');
			el.querySelector('.accordion-row__icon')?.classList.add('rotate-45');
			el.querySelector('.accordion-open-button .svg-icon use')?.setAttribute(
				'xlink:href',
				'images/icons.svg#CTA-expand',
			);

			Velocity(answer, { opacity: 0 }, { duration: 200 });

			Velocity(answer, 'slideUp', { duration: 200 });
		}
	}
}

export default Accordion;
