import gsap from 'gsap';
import { SplitText } from 'gsap/all';
import Swiper from 'swiper';
import { Navigation, Autoplay } from 'swiper/modules';
import 'swiper/css';

gsap.registerPlugin(SplitText);

class CodewordsSwiper {
	constructor(element) {
		this.element = element;
		this.swiper = element?.querySelector('.swiper');

		if (!this.swiper) return;

		this.headers = this.element.querySelectorAll('.c-codewords-reel__header');
		this.headerActive = this.element.querySelector('.c-codewords-reel__header--active');
		this.btns = this.element.querySelectorAll('.c-codewords-reel__trigger');
		this.closeBtn = null;
		this.modal = null;
		this._swiper = null;
		this._modalSwiper = null;
		this.isOpen = false;

		// Fit the large header text to the container
		Array.from(this.headers).forEach((header) => this.fitText(header));

		// Open a modal on click of the button
		Array.from(this.btns)?.forEach((btn) => {
			btn.addEventListener('click', (e) => {
				e.preventDefault();
				this.openModal();
			});
		});

		const duration = 5000;
		const speed = 300;

		// Set up the first swiper which will be paused and only play when in view
		this._swiper = new Swiper(this.swiper, {
			modules: [Autoplay],
			direction: 'vertical',
			slidesPerView: 1,
			slidesPerGroup: 1,
			allowTouchMove: false,
			speed: speed,
			autoplay: {
				delay: duration,
			},
		});

		// Start with the swiper paused
		this._swiper.autoplay.stop();

		// Start/stop the swiper when it comes into/goes out of view
		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						this.activateIframes();
						this._swiper.autoplay.start();
					} else {
						this.deactivateIframes();
						this._swiper.autoplay.stop();
					}
				});
			},
			{
				rootMargin: '0% 0% 0% 0%',
			},
		);

		observer.observe(this.swiper);

		this.headerAnimation();
	}

	headerAnimation() {
		if (window.innerWidth < 768) return;

		// Set up the split text plugin on each header
		const splits = [];
		Array.from(this.headers).forEach((header, index) => {
			const split = new SplitText(header, { type: 'chars' });
			// Hide all but the first
			if (index > 0) gsap.set(split.chars, { y: 50, autoAlpha: 0 });
			splits.push(split);
		});

		// Animate out the current header
		this._swiper.on('beforeTransitionStart', () => {
			gsap.to(splits[this._swiper.previousIndex].chars, {
				duration: 0.2,
				y: 50,
				autoAlpha: 0,
				stagger: 0.05,
			});
		});

		// Animate in the new header
		this._swiper.on('slideChangeTransitionEnd', () => {
			gsap.to(splits[this._swiper.activeIndex].chars, {
				duration: 0.2,
				y: 0,
				autoAlpha: 1,
				stagger: 0.05,
			});
		});
	}

	openModal() {
		this.isOpen = true;
		this.toggleScroll();

		// Create the modal if it doesn't exist
		if (!this.modal) {
			this.createModal();

			// Close the modal on click of the newly created close button
			this.modal.querySelector('.modal__close').addEventListener('click', () => this.closeModal());
			// Close the modal on click outside the modal content
			this.modal.addEventListener('click', (e) => {
				if (!e.target.closest('.modal__content')) {
					this.closeModal();
				}
			});
			this.modal.addEventListener('keydown', (e) => {
				if (e.key === 'Escape') {
					this.closeModal();
				}
			});
		}

		// Set up the swiper in the modal
		if (!this._modalSwiper) {
			this._modalSwiper = new Swiper(this.modal.querySelector('.swiper'), {
				modules: [Navigation],
				direction: 'vertical',
				slidesPerView: 1,
				slidesPerGroup: 1,
				speed: 1000,
				navigation: {
					nextEl: this.modal.querySelector('.swiper-button-next'),
					prevEl: this.modal.querySelector('.swiper-button-prev'),
				},
			});

			// Autoplay videos on slide change, and update tab indexes
			this._modalSwiper.on('beforeTransitionStart', () => {
				this.deactivateIframes(this._modalSwiper, true);
				this.activateIframe(this._modalSwiper, true);
				// Re-trap focus if they got to last slide and the button became disabled (causing focus to leave this modal)
				if (!this.modal.contains(document.activeElement) && this.closeBtn) {
					this.closeBtn.focus();
				}
			});
		}

		// Activate the iframes in the modal
		this.activateIframe(this._modalSwiper, true);

		// Animate in the modal
		gsap.to(this.modal, {
			autoAlpha: 1,
			onComplete: () => {
				this.modal.classList.remove('modal--hidden');
				// Focus on the close button
				if (this.closeBtn) this.closeBtn.focus();

				// Add focus trap inside of the modal until it's closed
				this.trapFocus();
			},
		});
	}

	/**
	 * Create the modal element with slides and append to the body
	 */
	createModal() {
		this.modal = document.createElement('div');
		this.modal.classList.add('modal', 'modal--hidden');
		this.modal.innerHTML = `
			<button class="modal__close btn btn--bordered-white btn--icon-only btn--transparent" aria-label="Close Codewords"></button>
			<div class="modal__content-wrapper flex justify-center">
				<div class="modal__content w-full md:w-auto p-6 md:p-24">
					<div class="inline-flex items-center md:inline-block w-[65%] md:w-auto h-[99%] relative">
						<button
							class="swiper-button-prev !absolute top-1/2 -left-8 z-10 -translate-y-1/2 -translate-x-full -rotate-90 btn btn--bordered-white btn--icon-only btn--transparent"
							aria-label="Previous slide"
						></button>
						<figure class="swiper w-full md:w-auto h-auto md:h-full rounded-3xl bg-black aspect-[9/16] overflow-hidden">
							<div class="swiper-wrapper"></div>
						</figure>
						<button
							class="swiper-button-next !absolute top-1/2 -right-8 z-10 -translate-y-1/2 translate-x-full rotate-90 btn btn--bordered-white btn--icon-only btn--transparent"
							aria-label="Next slide"
						></button>
					</div>
				</div>
			</div>
		`;

		// Duplicate existing slides into the modal but with autoplay off and controls on
		Array.from(this._swiper.slides).forEach((slide) => {
			const iframe = slide.querySelector('iframe');
			const clonedIframe = iframe.cloneNode(true);

			const src = clonedIframe.getAttribute('data-src');
			const newDataSrc = src
				.replace('controls=0', 'controls=1')
				.replace('muted=1', 'muted=0')
				.replace('autoplay=0', 'autoplay=1');
			clonedIframe.setAttribute('data-src', newDataSrc);
			clonedIframe.removeAttribute('src');
			clonedIframe.tabIndex = -1;

			const slideNode = document.createElement('div');
			slideNode.classList.add('swiper-slide');
			slideNode.appendChild(clonedIframe);

			this.modal.querySelector('.swiper-wrapper').appendChild(slideNode);
		});

		document.body.appendChild(this.modal);
		this.closeBtn = this.modal.querySelector('.modal__close');
	}

	/**
	 * Animate out the modal
	 */
	closeModal() {
		if (this.boundFocusHandler) {
			this.modal.removeEventListener('keydown', this.boundFocusHandler);
			this.boundFocusHandler = null;
		}
		this.isOpen = false;
		gsap.to(this.modal, {
			autoAlpha: 0,
			onComplete: () => {
				this._modalSwiper.slideTo(0);
				this.modal.classList.add('modal--hidden');
				this.toggleScroll(false);
				this.deactivateIframes(this._modalSwiper, true);
				// Shift focus back to the main element
				this.btns[0].focus();
			},
		});
	}

	/**
	 * Trap focus within the modal
	 */
	trapFocus() {
		function handleFocus(event) {
			const isTabPressed = event.key === 'Tab';

			if (!isTabPressed) return;

			const focusableElements = this.modal.querySelectorAll(
				'a[href], button, textarea, input, select, iframe, [tabindex]:not([tabindex="-1"])',
			);
			const firstElement = focusableElements[0];
			const lastElement = focusableElements[focusableElements.length - 1];

			if (event.shiftKey) {
				// If Shift + Tab is pressed
				if (document.activeElement === firstElement) {
					event.preventDefault();
					lastElement.focus();
				}
			} else {
				// If Tab is pressed
				if (document.activeElement === lastElement) {
					event.preventDefault();
					firstElement.focus();
				}
			}
		}
		this.boundFocusHandler = handleFocus.bind(this);
		this.modal.addEventListener('keydown', this.boundFocusHandler);
	}

	/**
	 * Activates the iframe video for current slide in the given swiper instance
	 */
	activateIframe(swiper, updateTabIndex = false) {
		const iframe = swiper.slides[swiper.activeIndex].querySelector('iframe');
		if (iframe) {
			iframe.setAttribute('src', iframe.getAttribute('data-src'));
			if (updateTabIndex) iframe.tabIndex = 0;
		}
	}

	/**
	 * Activates all the iframe videos for the given swiper instance
	 */
	activateIframes(slides = this._swiper.slides) {
		Array.from(slides).forEach((slide) => {
			const iframe = slide.querySelector('iframe');
			if (iframe) {
				iframe.setAttribute('src', iframe.getAttribute('data-src'));
			}
		});
	}

	/**
	 * Remove the src attribute from all the iframes in the given swiper
	 */
	deactivateIframes(swiper = this._swiper, updateTabIndex = false) {
		Array.from(swiper.slides).forEach((slide) => {
			const iframe = slide.querySelector('iframe');
			if (iframe.getAttribute('src')) iframe.removeAttribute('src');
			if (updateTabIndex) iframe.tabIndex = -1;
		});
	}

	/**
	 * Fit the header text to the container
	 */
	fitText(textEl) {
		// Start off too big
		let fontSize = 40;
		textEl.style.fontSize = `${fontSize}vw`;

		const raf = () => {
			// Once the text fits, stop
			if (this.element.offsetWidth > textEl.offsetWidth) return;

			// decrease the font size and try again
			fontSize -= 1;
			textEl.style.fontSize = `${fontSize}vw`;

			requestAnimationFrame(raf);
		};

		requestAnimationFrame(raf);
	}

	/**
	 * Toggles the body scroll
	 */
	toggleScroll(disable = true) {
		if (disable) {
			window.LENIS.stop();
		} else {
			window.LENIS.start();
		}
	}
}

export default CodewordsSwiper;
