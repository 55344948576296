import gsap from 'gsap';

class ObserveContent {
	constructor() {
		this.content = document.querySelectorAll('.content-anim:not([data-content-init])');

		if (!this.content.length) return;

		Array.from(this.content).forEach((content) => {
			this.init(content);
		});
	}

	init(content) {
		content.dataset.contentInit = true;

		// If the content is in a grid, stagger the animations
		if (content.parentElement.classList.contains('grid')) {
			let parent = content.parentElement;
			let children = Array.from(parent.children);
			let index = children.indexOf(content);
			content.dataset.stagger = 1000 * (index % 3);
		}

		let stagger = parseInt(content.dataset.stagger, 10) / 10000 || 0;
		if (window.innerWidth < 768) stagger = 0;
		const yOffset = window.innerWidth < 768 ? 30 : 50;

		gsap.set(content, {
			y: yOffset,
			opacity: 0,
		});

		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						gsap.to(content, {
							y: 0,
							opacity: 1,
							delay: stagger,
							clearProps: 'all',
						});

						observer.unobserve(content);
					}
				});
			},
			{
				rootMargin: '0% 0% -5% 0%',
			},
		);

		observer.observe(content);
	}
}

export default ObserveContent;
