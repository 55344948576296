class FormReset {
	constructor() {
		this.resetBtns = document.querySelectorAll('.form__reset');
		if (!this.resetBtns.length) return;
		this.resetBtns.forEach((btn) => this.initBtn(btn));
	}

	initBtn(btn) {
		const form = btn.closest('form');
		const input = form.querySelector('.form__input');

		// Set the input class on page load
		input.classList.toggle('has--value', input.value);

		// Toggle the input class on keyup
		input.addEventListener('keyup', () => {
			input.classList.toggle('has--value', input.value);
		});

		// Reset the input value on button click
		btn.addEventListener('click', (e) => {
			e.preventDefault();
			input.value = '';
			input.focus();
			input.classList.remove('has--value');
		});
	}
}

export default FormReset;
