import gsap from 'gsap';

class FormFilters {
	constructor() {
		this.to = null;
		this.tl = null;
		this.isAnimating = false;
		this.element = document.querySelector('.form-filters');

		if (!this.element) return;

		this.filtersBtn = this.element.querySelector('.form-filters__open');
		this.filtersPopup = this.element.querySelector('.form-filters__popup');
		this.filtersPopupClose = this.element.querySelector('.form-filters__close');
		this.filtersResetBtn = this.filtersPopup?.querySelector('.form-filters__reset');
		this.removeFilterBtns = this.element.querySelectorAll('.form-filters__remove-filter');
		this.filterOpenCloseBtns = Array.from(
			this.element.querySelectorAll('.form-filters__button,.form-filters__panel-back'),
		);
		this.filterCheckboxes = Array.from(this.element.querySelectorAll('.form-filters__checkbox'));
		this.applyFiltersBtn = this.element.querySelector('.form-filters__apply');

		this.addEvents();
	}

	addEvents() {
		this.filtersBtn?.addEventListener('click', this.toggleFiltersPopup.bind(this, true), true);
		this.filterCheckboxes.forEach((checkbox) => {
			checkbox.addEventListener('change', this.onFilterChange.bind(this), true);
		});
		this.filterOpenCloseBtns.forEach((button) => {
			button.addEventListener(
				'click',
				this.toggleFilterPanel.bind(this, button.classList.contains('form-filters__button')),
				true,
			);
		});
		this.removeFilterBtns.forEach((button) => {
			button.addEventListener('click', this.removeFilter.bind(this), true);
		});
		this.boundCloseFiltersPopup = this.toggleFiltersPopup.bind(this, false);
		this.filtersPopup?.addEventListener('click', this.boundCloseFiltersPopup, true);
		this.filtersPopupClose?.addEventListener('click', this.boundCloseFiltersPopup, true);
		this.filtersResetBtn?.addEventListener('click', this.resetFilters.bind(this), true);
	}

	toggleFiltersPopup(open, e) {
		if (open) {
			document.addEventListener('keydown', this.boundCloseFiltersPopup);
			gsap.set(this.filtersPopup, {
				display: 'flex',
			});
			gsap.to(this.filtersPopup, {
				autoAlpha: 1,
			});
			requestAnimationFrame(() => {
				window.setTimeout(() => {
					this.filtersPopupClose?.focus();
				});
			});

			// Remove body scrolling
			window.LENIS.stop();
		} else if (
			(e.type === 'click' && (e.target === this.filtersPopup || e.target === this.filtersPopupClose)) ||
			e.code === 'Escape'
		) {
			const activePanel = this.element.querySelector('.form-filters__panel--active');
			if (activePanel) {
				this._toggleFilterPanel(false, activePanel.querySelector('.form-filters__panel-back'));
			}
			document.removeEventListener('keydown', this.boundCloseFiltersPopup);
			gsap
				.timeline()
				.to(this.filtersPopup, {
					autoAlpha: 0,
				})
				.set(this.filtersPopup, {
					display: 'none',
				})
				.then(() => {
					this.filtersBtn?.focus();
				});

			// Restore body scrolling
			window.LENIS.start();
		}
	}

	toggleFilterPanel(open, e) {
		const button = e.currentTarget;
		this._toggleFilterPanel(open, button);
	}

	_toggleFilterPanel(open, button) {
		const parent = button?.closest('.form-filters__option-row');
		const panel = parent?.querySelector('.form-filters__panel');
		if (open) {
			panel?.classList.remove('hidden');
			requestAnimationFrame(() => {
				panel?.classList.add('form-filters__panel--active');
				// Can do weird things trying to focus an element that's offscreen
				window.setTimeout(() => {
					panel?.querySelector('.form-filters__panel-back')?.focus();
				}, 300);
			});
		} else {
			panel?.classList.remove('form-filters__panel--active');
			parent?.querySelector('.form-filters__button')?.focus();
			// Allow the transition to animate before removing from tabindex
			requestAnimationFrame(() => {
				window.setTimeout(() => {
					// Ensure they haven't quickly opened it again
					if (!panel?.classList.contains('form-filters__panel--active')) {
						panel?.classList.add('hidden');
					}
				}, 300);
			});
		}
	}

	onFilterChange(e) {
		this.updateFiltersState(e.target);
	}

	updateFiltersState(target) {
		const targetName = target.getAttribute('data-name');

		// If they've changed the service then make sure we're querying from the Browse All Roles page
		// Otherwise it'll just default back to filter by the current service
		if (targetName?.startsWith('Service')) {
			this.element.action = this.element.getAttribute('data-triservice-action');
		}

		if (target.value === 'All') {
			// If they're checking "All" then uncheck all the rest for this filter
			Array.from(this.element.querySelectorAll(`input[data-name="${targetName}"]`)).forEach((checkbox) => {
				if (checkbox.value !== 'All') {
					checkbox.checked = false;
				}
			});
			if (!target.checked) {
				// "All" cannot be un-selected as such (you'd have to select something else to de-select it)
				target.checked = true;
			}
		} else if (target.checked) {
			// They selected something, ensure "All" is no longer checked
			const allOption = this.element.querySelector(`input[data-name="${targetName}"][value="All"]`);
			if (allOption) allOption.checked = false;
		} else if (!this.element.querySelectorAll(`input[data-name="${targetName}"]:checked`).length) {
			// They de-selected the last item, so set it back to "All"
			const allOption = this.element.querySelector(`input[data-name="${targetName}"][value="All"]`);
			if (allOption) allOption.checked = true;
		}

		// Update the summary on the filter button
		const summary = Array.from(this.element.querySelectorAll(`input[data-name="${targetName}"]:checked`))
			.map((el) => el.dataset.title)
			.join(', ');
		const summaryEl = target.closest('.form-filters__option-row')?.querySelector('.form-filters__selected-value');
		if (summaryEl) {
			summaryEl.innerText = summary;
		}
	}

	resetFilters() {
		// Emulates checking the "All" option for each filter
		Array.from(this.element.querySelectorAll('input[value="All"]')).forEach((el) => {
			this.updateFiltersState(el);
		});
	}

	removeFilter(e) {
		const name = e.currentTarget.getAttribute('data-name');
		const value = e.currentTarget.getAttribute('data-value');
		const checkbox = this.element.querySelector(`input[data-name="${name}"][value="${value}"]`);
		if (checkbox) {
			checkbox.checked = false;
			this.updateFiltersState(checkbox);
			this.applyFiltersBtn?.click();
		}
	}
}

export default FormFilters;
