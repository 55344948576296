import $ from 'jquery';
import 'slick-carousel';

class Carousel {
	constructor(element) {
		if (!element) return;

		this.slider = $(element).find('.c-carousel__slider');
		this.nextBtn = $(element).find('.c-carousel__next');
		this.prevBtn = $(element).find('.c-carousel__prev');

		this.slider.slick({
			accessibility: false,
			slidesToScroll: 4,
			infinite: element.dataset.carouselInfinite === 'true' ? true : false,
			speed: 300,
			variableWidth: true,
			nextArrow: this.nextBtn,
			prevArrow: this.prevBtn,
			responsive: [
				{
					breakpoint: 600,
					settings: {
						slidesToScroll: 1,
					},
				},
				{
					breakpoint: 900,
					settings: {
						slidesToScroll: 2,
					},
				},
				{
					breakpoint: 1350,
					settings: {
						slidesToScroll: 3,
					},
				},
			],
		});
	}
}

export default Carousel;
