import gsap from 'gsap';

class AccordionToggle {
	constructor(element) {
		if (!element) return;

		this.element = element;
		this.anchors = element.querySelectorAll('.c-accordion__anchor');
		this.target = element.querySelectorAll('.c-accordion__target');

		this.anchors?.forEach((anchor) => {
			anchor.addEventListener('click', (e) => {
				e.preventDefault();

				const panel = anchor.closest('.c-accordion__panel');
				const isActive = panel.classList.contains('c-accordion__panel--active');
				const target = panel.querySelector('.c-accordion__target');
				const content = panel.querySelector('.c-accordion__content');
				const image = panel.querySelector('.c-accordion__image');

				// Close active panel if it's not the current target
				this.target.forEach((t) => {
					if (t !== target && t.closest('.c-accordion__panel').classList.contains('c-accordion__panel--active')) {
						gsap.to(t, {
							height: 0,
							clearProps: 'all',
							onComplete: () => {
								t.closest('.c-accordion__panel').classList.remove('c-accordion__panel--active');
							},
						});
					}
				});

				// Toggle current panel
				gsap.to(target, {
					height: isActive ? 0 : 'auto',
					clearProps: 'all',
					onBegin: () => {
						if (!isActive) panel.classList.toggle('c-accordion__panel--active', !isActive);
					},
					onComplete: () => {
						if (isActive) panel.classList.toggle('c-accordion__panel--active', !isActive);
					},
				});

				// Stagger content inside panel for slight parallax effect
				gsap.set([content, image], {
					y: isActive ? 0 : 50,
					opacity: isActive ? 1 : 0,
				});

				gsap.to([content, image], {
					y: isActive ? 50 : 0,
					opacity: isActive ? 0 : 1,
					clearProps: 'opacity, transform',
					stagger: -0.05,
				});
			});
		});
	}
}

export default AccordionToggle;
