import gsap from 'gsap';

class MainMenu {
	constructor() {
		this.header = document.querySelector('.header');
		this.menu = document.querySelector('.main-nav');

		if (!this.menu) return;

		this.spacer = document.querySelector('.header__spacer');
		this.masthead = this.header.querySelector('.header__masthead');
		this.trigger = this.header.querySelector('.main-nav--trigger');
		this.close = this.header.querySelector('.main-nav--close');
		this.itemsWrapper = this.menu.querySelector(':scope > ul');
		this.items = this.menu.querySelectorAll('.main-nav__item');
		this.links = this.menu.querySelectorAll('.main-nav__link');
		this.navSearch = this.menu.querySelector('.main-nav__search');
		this.activeNavItem = this.menu.querySelector('.main-nav__link--active');

		this.isOpen = false;
		this.isSlim = false;
		this.scrollPos = 0;
		this.isAnimating = false;

		this.setMqSizes();

		window.addEventListener('load', () => this.events());
	}

	events() {
		// Initial call to scroll behavior incase the user lands down the page and it needs to trigger a state change
		this.scroller();
		window.addEventListener('scroll', () => this.scroller());

		// Update media query sizes on resize
		window.addEventListener('resize', () => this.setMqSizes());

		// Toggle the menu on click
		this.trigger.addEventListener('click', () => {
			// On desktop we just need to expand the header which removes the trigger and only shows again on scroll down
			if (this.mqLg) {
				this.grow();
			}
			// On smaller screens we need to show/hide the menu
			else {
				if (this.isOpen) {
					this.closeMenu();
				} else {
					this.toggleHeaderBg();
					this.showMenu();
				}

				this.toggleScroll();
			}
		});

		// Close the menu on click (mobile)
		this.close.addEventListener('click', () => {
			this.closeMenu();
			this.toggleScroll();
		});

		// Close the menu on click outside of the menu
		document.addEventListener('click', (e) => {
			if (this.isOpen && !e.target.closest('.header__masthead') && !e.target.closest('.main-nav')) {
				if (this.mqMd && !this.mqLg) this.closeMenu();
				this.hideActiveDropdowns();
			}
		});

		// Toggle nav site-wide search form
		Array.from(document.querySelectorAll('.site-search__toggle')).forEach((btn) => {
			if (document.body.classList.contains('ApplyPage')) return;
			btn.addEventListener('click', (e) => {
				e.preventDefault();
				this.toggleDropdown(this.navSearch);
			});
		});

		this.links.forEach((link) => {
			const dropdown = link.nextElementSibling;

			link.addEventListener('click', (e) => {
				e.preventDefault();

				// If there is no dropdown, just go to the link
				if (!dropdown) window.location = link.href;

				// Toggle active classes on larger screens
				if (this.mqMd) {
					this.links.forEach((l) => l.classList.remove('main-nav__link--active'));
					if (!dropdown.classList.contains('main-nav__dropdown--active')) link.classList.add('main-nav__link--active');
				}

				// Toggle the dropdown
				this.toggleDropdown(dropdown);
			});

			dropdown?.querySelector('.main-nav__dropdown--close')?.addEventListener('click', () => {
				this.hideActiveDropdowns();
			});

			// Add a check for hashed links targeting the current page and scroll to them nicely
			dropdown?.querySelectorAll('a:not(.btn)').forEach((link) => {
				link.addEventListener('click', (e) => {
					const href = link.href;

					const linkUrl = new URL(href, window.location.origin);
					const currentUrl = new URL(window.location.href);

					// Check if the link's URL has the same origin and pathname as the current URL and has a hash
					if (linkUrl.origin === currentUrl.origin && linkUrl.pathname === currentUrl.pathname && linkUrl.hash) {
						e.preventDefault();

						// Scroll to the anchor element
						document.querySelector(linkUrl.hash)?.scrollIntoView({
							behavior: 'smooth',
						});

						// Close the menu
						if (this.isOpen) this.closeMenu();
					} else {
						// Redirect to the link's href if it's not an anchor on the current page
						window.location.href = href;
					}
				});
			});
		});
	}

	scroller() {
		if (document.body.classList.contains('ApplyPage')) return;

		const pos = window.scrollY;

		// Shrink the header if the user scrolls down
		if (pos > 10 && pos > this.scrollPos) {
			this.shrink();
		}
		// Expand the header if the user scrolls up
		else if (pos < this.scrollPos) {
			this.grow();
		}

		// If the header is black when the user is at the top of the page, make it transparent
		if (pos < 10 && this.header.classList.contains('header--black')) this.toggleHeaderBg(false);

		this.scrollPos = pos;
	}

	/**
	 * Sets the media query sizes
	 */
	setMqSizes() {
		// Need to match the media queries in the CSS
		this.mqLg = window.innerWidth >= 1280;
		this.mqMd = window.innerWidth >= 1024;
		this.mqSm = window.innerWidth >= 768;
		this.mqXs = window.innerWidth >= 640;

		if (this.spacer) this.spacer.style.height = `${this.header.offsetHeight}px`;
	}

	/**
	 * Resets the header bar to full size
	 */
	grow() {
		if (!this.isSlim || this.isAnimating) return;

		this.header.classList.remove('header--slim');
		this.isSlim = false;

		// Desktop has more to show than smaller screens
		if (this.mqMd) {
			this.isAnimating = true;

			if (this.mqLg) {
				gsap.to(this.trigger.parentElement, {
					autoAlpha: 0,
					duration: 0.3,
					clearProps: 'all',
				});

				gsap.to(this.trigger.parentElement.previousElementSibling, {
					delay: 0.5,
					autoAlpha: 1,
					x: 0,
					clearProps: 'all',
				});

				gsap.to(this.menu, {
					autoAlpha: 1,
					height: 'auto',
					clearProps: 'all',
				});

				gsap.to(this.items, {
					stagger: 0.05,
					duration: 0.2,
					autoAlpha: 1,
					y: 0,
					clearProps: 'all',
				});
			}

			gsap.to(this.masthead, {
				paddingTop: '3rem',
				paddingBottom: '3rem',
				clearProps: 'all',
				onComplete: () => {
					this.isAnimating = false;
				},
			});
		}
	}

	/**
	 * Shrinks the header bar down to a single slimmer bar
	 */
	shrink() {
		if (this.isSlim || this.isOpen || this.isAnimating) return;

		this.header.classList.add('header--slim');
		this.isSlim = true;
		this.isAnimating = true;

		// Desktop has more to show than smaller screens
		if (this.mqLg) this.lgShrink();
		if (this.mqMd) this.mdShrink();
		else this.smShrink();
	}

	lgShrink() {
		this.toggleHeaderBg();

		// Hides the service nav
		gsap.to(this.trigger.parentElement.previousElementSibling, {
			autoAlpha: 0,
			x: 20,
			duration: 0.3,
			onComplete: () => {
				gsap.set(this.trigger.parentElement, {
					autoAlpha: 0,
					display: 'flex',
				});
			},
		});

		// Shows the trigger
		gsap.to(this.trigger.parentElement, {
			delay: 0.5,
			autoAlpha: 1,
		});

		// Hides the main nav
		gsap.to(this.menu, {
			autoAlpha: 0,
			height: 0,
		});

		gsap.to(this.items, {
			stagger: -0.05,
			duration: 0.2,
			autoAlpha: 0,
			y: 20,
		});

		gsap.to(this.masthead, {
			paddingTop: '1.5rem',
			paddingBottom: '1.5rem',
			onComplete: () => {
				this.isAnimating = false;
			},
		});
	}

	mdShrink() {
		if (window.scrollY > 10) {
			this.toggleHeaderBg();

			gsap.to(this.masthead, {
				paddingTop: '1.5rem',
				paddingBottom: '1.5rem',
				onComplete: () => {
					this.isAnimating = false;
				},
			});
		} else {
			this.toggleHeaderBg(false);
			this.isAnimating = false;
		}
	}

	smShrink() {
		if (window.scrollY > 10) {
			this.toggleHeaderBg();
		} else {
			this.toggleHeaderBg(false);
		}
		this.isAnimating = false;
	}

	/**
	 * Shows the menu
	 */
	showMenu() {
		this.header.classList.add('header--open');
		this.isOpen = true;

		gsap.set(this.menu, {
			display: 'block',
			height: 0,
			autoAlpha: 0,
		});

		gsap.to(this.menu, {
			autoAlpha: 1,
			height: this.mqMd ? 'auto' : window.innerHeight,
		});

		gsap.to(this.items, {
			stagger: 0.05,
			duration: 0.2,
			autoAlpha: 1,
			y: 0,
			clearProps: 'all',
		});
	}

	/**
	 * Closes the menu
	 */
	closeMenu() {
		this.header.classList.remove('header--open');
		this.isOpen = false;

		// The apply page needs to default to transparent
		if (document.body.classList.contains('ApplyPage')) {
			this.toggleHeaderBg(false);
		}

		gsap.to(this.menu, {
			autoAlpha: 0,
			height: this.mqSm ? 0 : this.masthead.offsetHeight,
			clearProps: 'all',
		});

		gsap.to(this.items, {
			stagger: -0.05,
			duration: 0.2,
			autoAlpha: 0,
			y: 20,
			clearProps: 'all',
		});

		// Close any opened dropdowns
		this.hideActiveDropdowns();
	}

	/**
	 * Toggles the header background color
	 */
	toggleHeaderBg(black = true) {
		if (!this.isOpen) this.header.classList.toggle('header--black', black);
	}

	/**
	 * Toggles a provided dropdown element
	 * @param {HTMLElement} dropdown The dropdown to toggle
	 */
	toggleDropdown(dropdown) {
		if (this.mqMd) {
			this.toggleHeaderBg();
			this.hideActiveDropdowns(true);
		}

		if (dropdown.classList.contains('main-nav__dropdown--active')) {
			this.hideActiveDropdowns();
		} else {
			this.isOpen = true;
			this.header.classList.add('header--open');
			dropdown.classList.add('main-nav__dropdown--active');

			gsap.set(dropdown, {
				top: this.mqMd ? this.header.offsetHeight : this.itemsWrapper.offsetTop,
				height: this.mqMd ? 'auto' : window.innerHeight - this.itemsWrapper.offsetTop,
				autoAlpha: 0,
				display: 'block',
				x: this.mqMd ? 0 : 50,
				onComplete: () => {
					// Shift focus into the search dropdown
					if (dropdown === this.navSearch) {
						// Element doesn't seem to quite be ready/allowed focus even after onComplete, so delay a little
						setTimeout(() => {
							this.navSearch.querySelector('input').focus();
						}, 100);
					}
				},
			});

			gsap.set(dropdown.querySelectorAll(':scope > div'), {
				autoAlpha: 0,
				x: this.mqLg ? 0 : 30,
				y: this.mqLg ? 30 : 0,
			});

			gsap.to(dropdown, {
				autoAlpha: 1,
				x: 0,
			});

			gsap.to(dropdown.querySelectorAll(':scope > div'), {
				autoAlpha: 1,
				x: 0,
				y: 0,
				stagger: 0.1,
			});
		}
	}

	/**
	 * Hides all active dropdowns
	 * @param {boolean} contentOnly If true, the dropdown content will animate out instead of the dropdown container
	 */
	hideActiveDropdowns(contentOnly = false) {
		const dropdowns = this.menu.querySelectorAll('.main-nav__dropdown--active');
		const dropdownsContent = this.menu.querySelectorAll('.main-nav__dropdown--active > div');

		// This gives the impression of the dropdown staying open and only the content swapping
		if (contentOnly) {
			gsap.set(dropdowns, {
				zIndex: -1,
			});

			gsap.to(dropdowns, {
				autoAlpha: 0,
				delay: 0.5,
				clearProps: 'all',
				onComplete: () => {
					dropdowns.forEach((dropdown) => dropdown.classList.remove('main-nav__dropdown--active'));
				},
			});

			gsap.to(dropdownsContent, {
				duration: 0.2,
				autoAlpha: 0,
			});
		} else {
			// Remove active class from main nav items and reset the page nav if there is one
			this.links.forEach((l) => l.classList.remove('main-nav__link--active'));
			if (this.activeNavItem) this.activeNavItem.classList.add('main-nav__link--active');

			this.header.classList.remove('header--open');
			if (this.mqMd) {
				this.isOpen = false;
				this.toggleScroll();
			}

			gsap.to(dropdowns, {
				autoAlpha: 0,
				x: this.mqMd ? 0 : 50,
				y: this.mqMd ? 50 : 0,
				clearProps: 'all',
				onComplete: () => {
					dropdowns.forEach((dropdown) => dropdown.classList.remove('main-nav__dropdown--active'));
				},
			});
		}
	}

	/**
	 * Toggles the body scroll
	 */
	toggleScroll() {
		if (this.mqLg) return;

		if (this.isOpen) {
			window.LENIS.stop();
		} else {
			window.LENIS.start();
		}
	}
}

export default MainMenu;
