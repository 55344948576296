import gsap from 'gsap';

class FullHeightSlider {
	constructor(element) {
		if (!element) return;

		this.element = element;
		this.slides = Array.from(this.element.querySelectorAll('.c-full-height-slider__slide'));
		this.buttonsWrapper = this.element.querySelector('.c-full-height-slider__nav');
		this.buttons = Array.from(this.element.querySelectorAll('.c-full-height-slider__nav-item'));
		this.isAnimating = false;

		this.buttons.forEach((button) => {
			button.addEventListener('click', (e) => {
				e.preventDefault();

				if (this.isAnimating || button.classList.contains('c-full-height-slider__nav-item--active')) return;

				const targetId = button.dataset.id;

				this.target = this.slides.find((slide) => slide.dataset.id === targetId);
				this.oldTarget = this.element.querySelector('.c-full-height-slider__slide--active');
				this.button = button;

				this.animate();
			});
		});
	}

	animate() {
		if (!this.target) return;

		this.isAnimating = true;

		this.direction = parseInt(this.target.dataset.id, 10) > parseInt(this.oldTarget.dataset.id, 10) ? 'next' : 'prev';
		this.buttons.forEach((slide) => slide.classList.remove('c-full-height-slider__nav-item--active'));
		this.button.classList.add('c-full-height-slider__nav-item--active');

		this.timeline = gsap.timeline({
			onComplete: () => {
				this.isAnimating = false;
			},
		});

		if (window.innerWidth < 768) {
			this.smallScreen();
		} else {
			this.largeScreen();
		}
	}

	smallScreen() {
		const currentHeight = this.oldTarget.offsetHeight + this.buttonsWrapper.offsetHeight;
		const targetHeight = this.target.offsetHeight + this.buttonsWrapper.offsetHeight;

		this.timeline
			// set the height of the slider to the current slide height so when the old target is set to absolute it doesn't collapse
			.set(this.element, {
				height: currentHeight,
			})
			//  set the old target to absolute so it doesn't affect the layout
			.set(this.oldTarget, {
				position: 'absolute',
			})
			// fade out the old slide content, staggered for effect
			.to([this.oldTarget.querySelector('img'), this.oldTarget.querySelector('.c-full-height-slider__content')], {
				x: this.direction === 'next' ? -50 : 50,
				autoAlpha: 0,
				stagger: 0.05,
			})
			// At the same time animate the height of the slider to the new slide height
			.to(
				this.element,
				{
					height: targetHeight,
				},
				'<',
			)
			// Get the target ready to animate in
			.set(this.target, {
				position: 'relative',
				autoAlpha: 0,
			})
			.set(
				[this.target.querySelector('img'), this.target.querySelector('.c-full-height-slider__content')],
				{
					x: this.direction === 'next' ? 50 : -50,
				},
				'<',
			)
			// fade in the new slide
			.to(this.target, {
				autoAlpha: 1,
			})
			// fade in the new slide content, staggered for effect
			.to(
				[this.target.querySelector('img'), this.target.querySelector('.c-full-height-slider__content')],
				{
					x: 0,
					stagger: 0.05,
					onComplete: () => {
						// remove the active class from the old slide
						this.oldTarget.classList.remove('c-full-height-slider__slide--active');
						// add the active class to the new slide
						this.target.classList.add('c-full-height-slider__slide--active');

						// Remove styles at the end of the animation
						this.element.querySelectorAll('[style]').forEach((el) => el.removeAttribute('style'));
					},
				},
				'<',
			);
	}

	largeScreen() {
		this.timeline
			// fade/scale out the old slide image
			.to(this.oldTarget.querySelector('img'), {
				autoAlpha: 0,
				scale: 1.075,
				clearProps: 'all',
			})
			// fade/transform out the old slide content
			.to(
				this.oldTarget.querySelector('.c-full-height-slider__content'),
				{
					autoAlpha: 0,
					y: 30,
					clearProps: 'all',
					onComplete: () => {
						// remove the active class from the old slide
						this.oldTarget.classList.remove('c-full-height-slider__slide--active');
						// add the active class to the new slide
						this.target.classList.add('c-full-height-slider__slide--active');
					},
				},
				'<',
			)
			// Set the target content so we can animate it in
			.set(this.target, {
				autoAlpha: 0,
			})
			.set(this.target.querySelector('img'), {
				scale: 1.075,
			})
			.set(this.target.querySelector('.c-full-height-slider__content'), {
				y: 30,
			})
			// fade in the new slide
			.to(this.target, {
				autoAlpha: 1,
				clearProps: 'all',
			})
			// scale in the new slide image
			.to(
				this.target.querySelector('img'),
				{
					scale: 1,
					clearProps: 'all',
				},
				'<',
			)
			// fade/transform in the new slide content
			.to(
				this.target.querySelector('.c-full-height-slider__content'),
				{
					y: 0,
					clearProps: 'all',
				},
				'<',
			);
	}
}

export default FullHeightSlider;
