import $ from 'jquery';
import 'slick-carousel';

const updateImageHeights = () => {
	const height = $('.service-hero__carousel').height();
	$('.service-hero__carousel img').height(height);
};

const heroCarousel = function () {
	const duration = 5000;
	const speed = 300;

	const video = document.querySelector('.service-hero__video');
	const carousels = $('.service-hero__carousel').slick({
		slidesToScroll: 1,
		infinite: true,
		speed: speed,
		autoplay: true,
		autoplaySpeed: duration,
		nextArrow: false,
		prevArrow: false,
		fade: true,
	});

	carousels.each((i, el) => {
		const container = $(el).closest('.service-hero');
		const pauseBtn = container.find('.pause-button');
		pauseBtn.addClass('pause-button--playing');
		pauseBtn.removeClass('pause-button--paused');
		pauseBtn.css({ '--pause-speed': `${duration + speed}ms` });
	});

	// NOTE - Reused for video pause buttons elsewhere
	const $pause = $('.pause-button');

	$pause.on('click', (e) => {
		const $btn = $(e.currentTarget);
		const vid = $btn.closest('.js-pause-video-parent').find('video')[0];
		if ($btn.hasClass('pause-button--playing')) {
			carousels.slick('slickPause');
			vid?.pause();
		} else {
			carousels.slick('slickPlay');
			vid?.play();
		}
		$btn.toggleClass('pause-button--playing');
		$btn.toggleClass('pause-button--paused');
	});

	// If the hero is showing a video instead of a carousel, update the button animation speed to the video duration and start playing it
	if (video) {
		const videoBtn = video.closest('.service-hero').querySelector('.pause-button');
		if (videoBtn) {
			videoBtn.style.setProperty('--pause-speed', `${video.duration * 1000}ms`);
			videoBtn.classList.add('pause-button--playing');
			videoBtn.classList.remove('pause-button--paused');
			video.play();
		}
	}

	$(window).on('load', updateImageHeights);

	let to = null;
	window.addEventListener('resize', () => {
		// Add a delay for performance
		clearTimeout(to);
		to = setTimeout(updateImageHeights, 150);
	});
};

export default heroCarousel;
