import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);

class Skiplinks {
	constructor() {
		this.element = document.querySelector('.skip-links');

		if (!this.element) return;

		this.dropdown = this.element.querySelector('.skip-links select');
		this.skiplinks = this.element.querySelectorAll('.skip-links a[data-id]');

		this.addEvents();
		this.observe();
	}

	addEvents() {
		this.skiplinks?.forEach((item) => {
			item.addEventListener('click', (e) => {
				e.preventDefault();
				this.jumpToSection(item);
			});
		});

		this.dropdown?.addEventListener(
			'change',
			(e) => {
				e.preventDefault();
				this.jumpToSection(this.dropdown);
			},
			false,
		);
	}

	observe() {
		this.skiplinks?.forEach((item) => {
			const sectionID = item.dataset.id || '';
			const section = document.getElementById(sectionID);

			if (!section) return;

			const observer = new IntersectionObserver(
				(entries) => {
					entries.forEach((entry) => {
						if (entry.isIntersecting) this.setActiveLink(item);
					});
				},
				{
					rootMargin: '0% 0% -50% 0%',
				},
			);

			observer.observe(section);
		});
	}

	setActiveLink(link) {
		this.skiplinks?.forEach((item) => item.removeAttribute('style'));
		link.style.opacity = 1;
		if (this.dropdown) this.dropdown.value = link.dataset.id;
	}

	jumpToSection(el) {
		let sectionID;

		if (el.options) {
			const option = el.options[el.selectedIndex];
			sectionID = option.dataset.id;
		} else {
			sectionID = el.dataset.id;
		}

		if (!sectionID) return;

		// get element by id and its offset top
		const section = document.getElementById(sectionID);
		const sectionY = section?.offsetTop || 0;

		// Determine the header offset
		let headerOffset = window.innerWidth <= 1280 ? 110 : 195;
		// If scrolling up, then header is going to shrink (which is why we can't just dynamically get the header height)
		if (section?.offsetTop > window.scrollY) headerOffset = 80;

		gsap.to(window, {
			duration: 0.5,
			scrollTo: sectionY - headerOffset,
		});
	}
}

export default Skiplinks;
